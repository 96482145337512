import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAccessToken();
    const refreshToken = this.authService.getRefreshToken();

    if (accessToken) {
      request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + accessToken)});
    }

    if (refreshToken) {
      request = request.clone({headers: request.headers.set('X-Auth-Token', refreshToken)});
    }

    return next.handle(request);
  }
}
