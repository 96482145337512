import { Component, EventEmitter, Input, OnInit,Output } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent {
  @Input() iconsName;
  @Output() theClick = new EventEmitter();
  title = 'SoundCafe';
  public breadcrumb = '';

  constructor(private authService: AuthService, private router: Router) {
    this.setBreadcrumb(router.url);
    router.events.subscribe({
      next: (val: any) => this.setBreadcrumb(val.urlAfterRedirects)
    });
  }

  setBreadcrumb(url) {
    const trans = {
      calendars: 'Календарь',
      equipments: 'Оборудование',
      categories: 'Категории',
      reports: 'Отчеты',
      wall: 'Стена',
      events: 'Проекты',
      users: 'Пользователи/Офисы',
      profile: 'Профиль'
    };

    for (const i in trans) {
      if (url && new RegExp(`^\/${i}`).test(url)) {
        return (this.breadcrumb = trans[i]);
      }
    }
  }

  change() {
    this.iconsName = !this.iconsName;
    this.theClick.emit(this.iconsName);
  }

  logout(): void {
    this.authService.logout();
  }
}
