import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Event } from '@app/shared/interfaces/event';
import * as dayjs from "dayjs";

@Component({
  selector: 'app-modal-print',
  templateUrl: './modal-print.component.html'
})



export class ModalPrintComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  isPrinted = false;

  reservationsData = [];

  @Input() event: Event;

  constructor(
    private formBuilder: FormBuilder,
    private modal: NzModalRef,
  ) {}

  ngOnInit(): void {

    this.reservationsData = this.event.reservations;

    this.form = this.formBuilder.group({
      reservations: new FormArray([]),
    });

    this.addReservationCheckbox()
  }

  private addReservationCheckbox() {
    this.reservationsData.forEach((item) => {
      this.reservationFormArray.push(new FormControl(false));
    });
  };

  get reservationFormArray() {
    return this.form.controls.reservations as FormArray;
  }

  changeCheckbox() {
    this.isPrinted = this.reservationFormArray.value.includes(true);
  }

  /**
   * Submit form
   */
  onSubmit(): void {

    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();

    let printedPage =  `
      <html>
        <head>
          <title>Мероприятие: ` + this.event.name + `(` + dayjs.unix(this.event.date_start_event).format('DD.MM.YYYY') +' - ' + dayjs.unix(this.event.date_finish_event).format('DD.MM.YYYY') + `)</title>
          <style>
          table {border-collapse: collapse;}
          table th { text-align: left;}
          table td,table th {border: 1px solid #8c8c8c;padding: 5px}
          </style>
        </head>
    <body onload="window.print();window.close()">`;

    printedPage += '<h1>'+ this.event.name +'</h1><hr>';

    printedPage += '<div class="date-event"><strong>Даты</strong>: '+ dayjs.unix(this.event.date_start_event).format('DD.MM.YYYY') +' - ' + dayjs.unix(this.event.date_finish_event).format('DD.MM.YYYY') + '</div>';

    if (this.event.person_charge) printedPage += '<div><strong>Имя менеджера</strong>: ' + this.event.person_charge.name + '</div>';

    if (this.event.person_charge.email) printedPage += '<div><strong>E-mail</strong>: ' + this.event.person_charge.email + '</div>';

    if (this.event.comment) printedPage += '<div><strong>Комментарий</strong>: ' + this.event.comment + '</div>';

    if (this.event.comment_light) printedPage += '<div><strong>Комментарий подрядчиков</strong>: ' + this.event.comment_light + '</div>';

    if (this.event.place || this.event.legacy_id) {
      printedPage += '<hr><h2>Сведения о площадке:</h2>';

      if (this.event.place) printedPage += '<div><strong>Площадка мероприятия</strong>: ' + this.event.place + '</div>';

      if (this.event?.legacy_id?.organisation) printedPage += '<div><strong>Организация</strong>: ' + this.event.legacy_id.organisation + '</div>';

      if (this.event?.legacy_id?.person) printedPage += '<div><strong>Ответственный</strong>: ' + this.event.legacy_id.person + '</div>';

      if (this.event?.legacy_id?.phone) printedPage += '<div><strong>Телефон</strong>: ' + this.event.legacy_id.phone + '</div>';

      if (this.event?.legacy_id?.email) printedPage += '<div><strong>Почта</strong>: ' + this.event.legacy_id.email+ '</div>';
    }

    printedPage += '<hr></h4><h2>Бронирования</h2>';

    this.reservationFormArray.value.forEach((item, index) => {
      if (item == true) {
        let nameReservation = this.event.reservations[index]?.name || 'Бронирование ' + (index + 1);
        let currentReservation = this.event.reservations[index];
        printedPage += '<div class="print-block"><h3 class="print-block-title">'+ nameReservation +'</h3>';
        printedPage += '<div><strong>Дата бронирования</strong>: '+ dayjs.unix(currentReservation.start).format('DD.MM.YYYY')+' - '+ dayjs.unix(currentReservation.finish).format('DD.MM.YYYY') +'</div>';
        printedPage += '<div><strong>Дата погрузки</strong>: '+ dayjs.unix(currentReservation.loading).format('DD.MM.YYYY');

        if (currentReservation.personals) {

          printedPage += '<div><strong>Персонал</strong>: ';

          currentReservation.personals.forEach(function(item, index) {
            if (index+1 == currentReservation.personals.length ) {
              printedPage += item.name;
            } else {
              printedPage += item.name +', ';
            }
          });
          printedPage += '</div>';
        }

        if (currentReservation.transports) {

          printedPage += '<div><strong>Транспорт</strong>: ';

          currentReservation.transports.forEach(function(item, index) {
            if (index+1 == currentReservation.transports.length ) {
              printedPage += item.name;
            } else {
              printedPage += item.name +', ';
            }
          });
          printedPage += '</div>';
        }

        if (currentReservation.equipments) {

          printedPage += '<h4>Список оборудования:</h4><div class="ant-table-body"> <table class="table-equipment"> <thead class="ant-table-thead"> <tr class="table-filter"> <th width="40%">Тип</th> <th width="40%">Оборудование</th> <th width="15%">Склад</th> <th width="5%">Количество</th> </tr> </thead> <tbody>';

          currentReservation.equipments.forEach(function(item, index) {

            console.log('item=', item);

            item.group.forEach(function(subitem, subindex) {
              if (item.group_id == subitem.id) printedPage += '<tr><td>' + item.category.name + '</td><td>' + item.name + '</td><td>' + subitem.name + '</td><td>' + item.reserved + '</td></tr>';
            });

          });
          printedPage += '</tbody> </table> </div>';
        }

      }
    });

    printedPage +=`</body>
        </html>`;

    popupWin.document.write(printedPage);
    popupWin.document.close();

    }

  destroyModal(res = null): void {
    this.modal.destroy(res);
  }
}
