import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({name: 'dateTimestamp'})
@Injectable()
export class DateTimestampPipe implements PipeTransform {
  transform(date: number | null, format: string): any {
    if (date) {
      return dayjs.unix(date).format(format);
    }
  }
}
