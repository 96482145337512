import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Legacy, LegacyEvent } from '@app/shared/interfaces/legacy';

@Injectable({
  providedIn: 'root'
})
export class LegacyService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/legacy`;
  }

  /**
   * Get all legacy
   */
  getAll(filter: string = ''): Observable<Legacy[]> {
    return this.http.get<ResponseItems<Legacy>>(this.url + filter).pipe(
        map((response: ResponseItems<Legacy>) => response.data)
    );
  }

  getOne(id: number): Observable<ResponseItem<Legacy>> {
    return this.http.get<ResponseItem<Legacy>>(`${this.url}/${id}`);
  }

  /**
   * Create user
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Legacy>> {
    return this.http.post<ResponseItem<Legacy>>(this.url, body);
  }

  /**
   * Get all users for event
   */
  getAllForEvent(): Observable<LegacyEvent[]> {
    return this.http.get<ResponseItems<LegacyEvent>>(`${this.url}-event`).pipe(
        map((response: ResponseItems<LegacyEvent>) => response.data)
    );
  }

}
/*
  /**
   * Update user
   *
   * @param body
   /
  update(body: User): Observable<ResponseItem<User>> {
    return this.http.put<ResponseItem<User>>(`${this.url}/${body.id}`, body);
  }

  /**
   * Delete user
   *
   * @param id
   *
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }
}*/