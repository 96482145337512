import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Organizer, OrganizerEvent } from '@app/shared/interfaces/organizer';

@Injectable({
  providedIn: 'root'
})
export class OrganizerService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/organizers`;
  }

  /**
   * Get all organizers
   */
  getAll(): Observable<ResponseItems<Organizer>> {
    return this.http.get<ResponseItems<Organizer>>(this.url);
  }

  /**
   * Get all organizers for event
   */
  getAllForEvent(): Observable<OrganizerEvent[]> {
    return this.http.get<ResponseItems<OrganizerEvent>>(`${this.url}-event`).pipe(
      map((res: ResponseItems<OrganizerEvent>) => res.data)
    );
  }

  /**
   * Create organizer
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Organizer>> {
    return this.http.post<ResponseItem<Organizer>>(this.url, body);
  }
}
