import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { EventService } from '@app/core/services/http/event.service';
import { Event, EventShowData } from '@app/shared/interfaces/event';

@Component({
  selector: 'app-show-event',
  templateUrl: './show-event.component.html',
  styleUrls: ['./show-event.component.scss']
})
export class ShowEventComponent implements OnChanges {
  @Input() data: EventShowData = null;
  @Output() dataChange = new EventEmitter<null>();
  event: Event = null;
  loading = false;

  constructor(private eventService: EventService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes?.data?.currentValue?.id || null;
    const previousValue = changes?.data?.previousValue?.id || null;

    if (currentValue && currentValue !== previousValue) {
      this.loading = true;
      this.event = null;
      this.getEvent();
    }
  }

  getEvent(): void {
    this.eventService.getOne(this.data.id).subscribe({
      next: res => {
        this.event = res.data;
        this.loading = false;
      }
    });
  }

  close() {
    this.event = null;
    this.dataChange.emit(null);
  }
}
