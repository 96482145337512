import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Event, EventCalendar } from '@app/shared/interfaces/event';
import {Reservation} from "@app/shared/interfaces/reservation";

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/events`;
  }


  validation(body): Observable<ResponseItem<Reservation>[]> {
    return this.http.post<ResponseItem<Reservation>[]>(this.url + '/validation',  body);
  }

  searchEquipments(body): Observable<ResponseItem<Reservation>> {
    return this.http.post<ResponseItem<Reservation>>(this.url + '/search-equipments',  body);
  }

  /**
   * Get all event
   */
  getAll(filter: string = ''): Observable<ResponseItems<Event>> {
    return this.http.get<ResponseItems<Event>>(this.url + filter);
  }

  getEventForEquipment(id: number | string): Observable<EventCalendar[]> {
    return this.http.get<ResponseItems<EventCalendar>>(`${this.apiUrl}/equipments-reserved/${id}`)
      .pipe(
        map(res => res.data)
      );
  }

  getOne(id: number | string): Observable<ResponseItem<Event>> {
    return this.http.get<ResponseItem<Event>>(`${this.url}/${id}`);
  }

  edit(id: number | string): Observable<ResponseItem<Event>> {
    return this.http.get<ResponseItem<Event>>(`${this.url}/${id}/edit`);
  }

  /**
   * Get one map
   *
   * @param id
   */
  getOneMap(id: number): Observable<Event> {
    return this.http.get<ResponseItem<Event>>(`${this.url}/${id}`)
      .pipe(map(d => d.data));
  }

  /**
   * Create event
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Event>> {
    return this.http.post<ResponseItem<Event>>(this.url, body);
  }

  /**
   * Update event
   *
   * @param body
   */
  createStep2(body): Observable<ResponseItem<Event>> {
    return this.http.put<ResponseItem<Event>>(`${this.url}/${body.id}/step2`, body);
  }

  /**
   * Update event
   *
   * @param body
   */
  createStep3(body): Observable<ResponseItem<Event>> {
    return this.http.put<ResponseItem<Event>>(`${this.url}/${body.id}/step3`, body);
  }

  /**
   * Update event
   *
   * @param body
   */
  createFinish(body): Observable<ResponseItem<Event>> {
    return this.http.put<ResponseItem<Event>>(`${this.url}/${body.id}/finish`, body);
  }

  /**
   * Update event
   *
   * @param body
   */
  update(body): Observable<ResponseItem<Event>> {
    return this.http.put<ResponseItem<Event>>(`${this.url}/${body.id}`, body);
  }

  /**
   * Add equipments for event
   *
   * @param id
   * @param body
   */
  equipments(id: number, body): Observable<ResponseItem<Event>> {
    return this.http.post<ResponseItem<Event>>(`${this.url}/${id}/equipments`, body);
  }

  /**
   * Delete event
   *
   * @param id
   */
  delete(id: number | string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
