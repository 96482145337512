import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { TransportService } from '@app/core/services/http/transport.service';

@Component({
  selector: 'app-modal-transport-add',
  templateUrl: './modal-transport-add.component.html'
})
export class ModalTransportAddComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private modal: NzModalRef,
    private transportService: TransportService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      phone: new FormControl(''),
      transport: new FormControl('')
    });
  }

  /**
   * Submit form
   */
  onSubmit(): void {
    this.submitted = this.form.invalid;

    if (this.submitted) {
      return;
    }

    this.transportService.create(this.form.getRawValue()).subscribe({
      next: res => {
        this.destroyModal(res);
      }
    });
  }

  destroyModal(res = null) {
    this.modal.destroy(res);
  }
}
