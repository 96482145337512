import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/auth.service';
import { NotificationService } from '@app/core/services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  title: string;
  message: string;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const isRefresh = req.url.includes('refresh');
        const refreshToken = this.authService.getRefreshToken();

        if (error.status === 401 && refreshToken && !isRefresh) {
          return this.authService.tokenRefresh()
            .pipe(
              switchMap(res => {
                if (res) {
                  req = this.updatedToken(req);
                  return next.handle(req);
                }

                this.authService.logout();
                throw throwError(error);
              })
            );
        } else if (error.status === 401 && isRefresh) {
          this.authService.logout();
          throw throwError(error);
        }

        this.title = error.statusText;
        this.message = error && error.error.message || '';

        if (error.status === 422) {
          this.title = this.message || this.title;
          this.message = '';
          if (error && error.error && error.error.errors) {
            Object.keys(error.error.errors).forEach(errorKey => {
              this.message += error.error.errors[errorKey][0];
            });
          }
        }

        this.notificationError();
        throw throwError(error);
      })
    );
  }

  private updatedToken(req) {
    const accessToken = this.authService.getAccessToken();
    const refreshToken = this.authService.getRefreshToken();

    if (accessToken) {
      req = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + accessToken)});
    }
    if (refreshToken) {
      req = req.clone({headers: req.headers.set('X-Auth-Token', refreshToken)});
    }

    return req;
  }

  /**
   * Notification error
   *
   * @param error
   */
  private notificationError() {
    this.notificationService.create('error', 'Ошибка', this.message);
  }
}
