<ng-container *ngIf="data?.id && data?.pageX && data?.pageY">
  <div class="se se-popover" [style.left.px]="data.pageX" [style.top.px]="data.pageY">
    <div class="se-popover-header">
      <span class="se-popover-title">{{ data.startEvent }} - {{ data.finishEvent }}</span>
      <span class="se-popover-close" (click)="close()"></span>
    </div>
    <a [routerLink]="'/events/' + data.id" class="se-popover-body">
      <div class="se-daygrid-event se-daygrid-block-event" [style.background]="data.backgroundColor">
        <div class="se-event-main">
          {{ data?.name || '' }}
        </div>
      </div>
      <ng-container *ngIf="loading">
        <app-shared-preloader [loading]="loading"></app-shared-preloader>
      </ng-container>
      <ng-container *ngIf="!loading && event.reservations && event.reservations.length">
        <ng-container *ngFor="let reservation of event.reservations; let i = index">
          <div class="se-daygrid-event-harness">
            <div class="se-daygrid-event se-daygrid-dot-event">
              <div class="se-daygrid-event-dot" [style.background]="data.backgroundColor"></div>
              <div class="se-event-title">
                {{reservation?.name || 'Бронирование № ' + (i + 1)}}
              </div>
            </div>
            <div class="se-event-time">Даты: {{reservation.start | dateTimestamp: 'DD.MM.YYYY'}} - {{ reservation.finish | dateTimestamp: 'DD.MM.YYYY' }}</div>
            <div class="se-event-time">Дата погрузки: {{ reservation.loading ? (reservation.loading | dateTimestamp: 'DD.MM.YYYY HH:mm') : '--' }}</div>
          </div>
        </ng-container>
      </ng-container>
    </a>
  </div>
</ng-container>
