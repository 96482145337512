import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { LegacyService } from '@app/core/services/http/legacy.service';

@Component({
  selector: 'app-modal-legacy-person-add',
  templateUrl: './modal-legacy-person-add.component.html'
})
export class ModalLegacyPersonAddComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private modal: NzModalRef,
    private LegacyService: LegacyService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({

      organisation: new FormControl(''),
      person: new FormControl('', Validators.required),
      email: new FormControl(''),
      phone: new FormControl(''),
    });
  }

  /**
   * Submit form
   */
  onSubmit(): void {
    this.submitted = this.form.invalid;

    if (this.submitted) {
      return;
    }

    this.LegacyService.create(this.form.getRawValue()).subscribe({
      next: res => {
        this.destroyModal(res);
      }
    });
  }

  destroyModal(res = null): void {
    this.modal.destroy(res);
  }
}
