<section class="top-header">
    <div class="container-fluid">
        <div class="row no-gutter">
            <div class="col-12">
                <div class="left-side">
                    <div class="left-side__burger">
                        <div class="hamburger hamburger--arrow-r">
                            <div class="hamburger-box">
                                <div class="hamburger-inner" (click)="change()"></div>
                            </div>
                        </div>
                    </div>
                    <div class="left-side__name-page">
                        {{title}}
                        <img alt="slash" class="icon-slash" [src]="'/assets/img/icons/slash.svg'">
                        <span>{{breadcrumb}}</span>
                    </div>
                </div>
                <div class="right-side">
                    <a [routerLink]="'/events/add'" class="right-side__add-event">
                        Добавить проект
                    </a>
                    <a [routerLink]="'/profile'" class="profile-logo">
                        <img alt="slash" class="icon-logo" [src]="'/assets/img/icons/user-menu.svg'">
                    </a>
                    <div class="right-side__log-out" (click)="logout()">
                        Выйти
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
