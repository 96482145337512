import { Component, EventEmitter,OnInit, Output } from '@angular/core';

import { MediaService } from '@app/core/services/http/media.service';
import { Media } from '@app/shared/interfaces/media';

@Component({
  selector: 'app-upload',
  templateUrl: 'upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Output()
  fileReady: EventEmitter<Media> = new EventEmitter<Media>();

  id: number;
  file: File;

  constructor(private mediaService: MediaService) {}

  ngOnInit(): void {}

  /**
   * File change
   *
   * @param event
   */
  fileChange(event): void {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.uploadFile();
    }
  }

  /**
   * Create image
   */
  uploadFile(): void {
    const formData = new FormData();
    formData.append('image', this.file, this.file.name);
    formData.append('name', this.file.name);
    formData.append('type', this.file.type);

    this.mediaService.create(formData).subscribe({
      next: res => {
        this.fileReady.emit(res.data);
      }
    });
  }
}
