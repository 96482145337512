import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'role'})
@Injectable()
export class RolePipe implements PipeTransform {
  transform(role: string | null): string {
    switch (role) {
      case 'user':
        return 'Пользователь';
      case 'admin':
        return 'Администратор';
      case 'manager':
        return 'Менеджер';
      default:
        return 'Без роли';
    }
  }
}
