<div class="slide-right-user">
  <div class="add-event-user">
    <nz-drawer
    [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzClosable]="false"
    [nzVisible]="isVisible"
    nzPlacement="right"
    (nzOnClose)="close()"
    [nzWidth]="420"
    >
      <form class="add-user-drawer-form" [formGroup]="form">
        <div class="add-event-user-block">
          <label>Email:</label>
          <input class="ant-input" placeholder="Email" formControlName="email"/>
        </div>
        <div class="add-event-user-block">
          <label>Имя:</label>
          <input class="ant-input" placeholder="Имя" formControlName="first_name"/>
        </div>
        <div class="add-event-user-block">
          <label>Фамилия:</label>
          <input class="ant-input" placeholder="Фамилия" formControlName="last_name"/>
        </div>
        <div class="add-event-user-block">
          <label>Телефон:</label>
          <input type="tel" class="ant-input" placeholder="Номер телефона" formControlName="phone"/>
        </div>
        <div class="add-event-user-block">
          <label>Пароль:</label>
          <input type="password" class="ant-input" placeholder="Введите пароль" formControlName="password"/>
        </div>
        <div class="add-event-user-block">
          <label>Статус:</label>
          <nz-radio-group formControlName="active">
            <label nz-radio nzValue="1">Активен</label>
            <label nz-radio nzValue="0">Неактивен</label>
          </nz-radio-group>
        </div>
        <div class="add-event-user-block">
          <label>Выбрать роль:</label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Выбрать роль" formControlName="role">
            <nz-option nzValue="admin" nzLabel="Администратор"></nz-option>
            <ng-container *ngIf="10 > 11">
              <nz-option nzValue="manager" nzLabel="Менеджер"></nz-option>
              <nz-option nzValue="user" nzLabel="Пользователь"></nz-option>
            </ng-container>
          </nz-select>
        </div>
        <div class="color-picker-drawer">
          <label>Цвет:</label>
          <input type="text" class="color-picker-input"
          [(colorPicker)]="color"
          [cpPresetColors]="['#359D93', '#BFE6EF', '#5993B7', '#04BFBF', '#4E41FF']"
          [cpMaxPresetColorsLength]="50"
          [cpPresetLabel]="'Выбрать цвет'"
          [cpPosition]="'left'"
          [style.background]="color"
          [cpAlphaChannel]="'disabled'"
          [cpOutputFormat]="'hex'"
          [value]="color"
          [cpAddColorButton]="true"
          [cpAddColorButtonText]="'Добавить'">
        </div>
        <div class="footer">
          <button type="button" class="ant-btn ant-btn-primary add-btn-user" (click)="onSubmit()">
            <span>Добавить</span>
          </button>
        </div>
      </form>
    </nz-drawer>
  </div>
</div>
