import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent {
  @Input() loading: boolean;

  constructor() {
  }
}
