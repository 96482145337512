import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private readonly InterceptorSkipHeaderImage = environment.InterceptorSkipHeaderImage;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.has(this.InterceptorSkipHeaderImage)) {
      const headers = request.headers.delete(this.InterceptorSkipHeaderImage);
      request = request.clone({headers});
    } else {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
      }
    }

    return next.handle(request);
  }
}
