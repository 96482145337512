<section class="left-slide-menu" [ngClass]="{iconsNameActive: iconsName}">
  <ul class="left-slide-menu__list">
    <li class="one-row-list">
      <a [routerLink]="'/calendars'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#calendar" />
        </svg>
        <span>Календарь</span>
      </a>
    </li>
    <li class="one-row-list">
      <a [routerLink]="'/events'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#shape" />
        </svg>
        <span>Проекты</span>
      </a>
    </li>
    <li class="one-row-list">
      <a [routerLink]="'/wall'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#icon-menu-wall" />
        </svg>
        <span>Стена</span>
      </a>
    </li>
    <li class="one-row-list">
      <a [routerLink]="'/users'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#icon-menu-users" />
        </svg>
        <span>Пользователи/Офисы</span>
      </a>
    </li>
    <li class="one-row-list">
      <a [routerLink]="'/equipments'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#icon-menu-equipment" />
        </svg>
        <span>Оборудование</span>
      </a>
    </li>
    <li class="one-row-list">
      <a [routerLink]="'/equipments-events'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#icon-menu-equipment" />
        </svg>
        <span>Оборудование на мероприятиях</span>
      </a>
    </li>
    <li class="one-row-list">
      <a [routerLink]="'/categories'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#category_btn" />
        </svg>
        <span>Категории</span>
      </a>
    </li>
    <li class="one-row-list">
      <a [routerLink]="'/reports'" class="one-row-list__link" routerLinkActive="activePage" [routerLinkActiveOptions]="{ exact: false }">
        <svg class="icon-menu">
          <use xlink:href="#icon-menu-reports" />
        </svg>
        <span>Отчеты</span>
      </a>
    </li>
  </ul>
</section>
