import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Notifications } from '@app/shared/interfaces/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications = new Subject<Notifications>();

  constructor() {
  }

  /**
   * Get alertSettings
   */
  getNotifications(): Observable<Notifications> {
    return this.notifications;
  }

  /**
   * Create alert
   *
   * @param type
   * @param title
   * @param body
   */
  create(type: string, title: string, body: string): void {
    this.notifications.next({
      type,
      title,
      body
    });
  }

  /**
   * Destroy
   */
  destroy() {
    this.notifications.unsubscribe();
    this.notifications = new Subject<Notifications>();
  }
}
