import { Pipe, PipeTransform } from '@angular/core';

import { CREATE,UPDATE } from '@app/app.constants';

@Pipe({name: 'buttonText'})
export class ButtonTextPipe implements PipeTransform {

  /**
   * Text
   *
   * @param text {string}
   * @return {string}
   */
  transform(text?: string): string {
    return text ? UPDATE : CREATE;
  }
}
