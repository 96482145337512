import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter,map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  url: Observable<boolean>;
  iconsNameActive = false;

  constructor(private router: Router) {
    this.url = this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map(e => {
          const url = e.url;
          return url.indexOf('/auth') === -1;
        })
      );
  }

  togleLeftMenu($event) {
    this.iconsNameActive = $event;
  }
}
