import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';

import { LeftMenuComponent } from '@app/shared/components/menu/left-menu/left-menu.component';
import { TopMenuComponent } from '@app/shared/components/menu/top-menu/top-menu.component';
import { NotificationComponent } from '@app/shared/components/message/notification/notification.component';
import {
  DrawerResponsibleOrganizerComponent,
  DrawerResponsiblePersonComponent,
  ModalPersonAddComponent,
  ModalLegacyPersonAddComponent,
  ModalPrintComponent,
  ModalModifyComponent,
  ModalTransportAddComponent,
  ShowEventComponent,
  ModalValidationEventComponent,
} from '@app/shared/components/modal';
import { PreloaderComponent } from '@app/shared/components/preloader/preloader.component';
import { SvgIconsComponent } from '@app/shared/components/svg-icons/svg-icons.component';
import { UploadComponent } from '@app/shared/components/upload/upload.component';

import { DateDisplayDirective } from './directives/date-display.directive';
import { NgZorroModule } from './ng-zorro.module';
import { ButtonTextPipe } from './pipes/button-text.pipe';
import { DateTimestampPipe } from './pipes/date-timestamp.pipe';
import { RolePipe } from './pipes/role.pipe';
import { StatusPipe } from './pipes/status.pipe';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ColorPickerModule,
    NgZorroModule,
  ],
  declarations: [
    DateTimestampPipe,
    RolePipe,
    StatusPipe,
    ButtonTextPipe,
    DateDisplayDirective,
    TopMenuComponent,
    LeftMenuComponent,
    SvgIconsComponent,
    NotificationComponent,
    PreloaderComponent,
    ModalTransportAddComponent,
    ModalPersonAddComponent,
    ModalLegacyPersonAddComponent,
    ModalPrintComponent,
    ModalModifyComponent,
    ModalValidationEventComponent,
    DrawerResponsiblePersonComponent,
    DrawerResponsibleOrganizerComponent,
    UploadComponent,
    ShowEventComponent
  ],
  exports: [
    DateTimestampPipe,
    RolePipe,
    StatusPipe,
    ButtonTextPipe,
    DateDisplayDirective,
    TopMenuComponent,
    LeftMenuComponent,
    SvgIconsComponent,
    NotificationComponent,
    PreloaderComponent,
    ModalTransportAddComponent,
    ModalPersonAddComponent,
    ModalLegacyPersonAddComponent,
    ModalPrintComponent,
    ModalModifyComponent,
    ModalValidationEventComponent,
    DrawerResponsiblePersonComponent,
    DrawerResponsibleOrganizerComponent,
    UploadComponent,
    NgZorroModule,
    ShowEventComponent
  ],
  entryComponents: [ModalTransportAddComponent, ModalPersonAddComponent,ModalLegacyPersonAddComponent, ModalPrintComponent, ModalModifyComponent, ModalValidationEventComponent]
})
export class SharedModule {

}
