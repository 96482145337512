import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { User, UserCalendar, UserEvent } from '@app/shared/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/users`;
  }

  /**
   * Get all users
   */
  getAll(filter: string = ''): Observable<User[]> {
    return this.http.get<ResponseItems<User>>(this.url + filter).pipe(
      map((response: ResponseItems<User>) => response.data)
    );
  }

  /**
   * Get all users for calendar
   */
  getAllForCalendar(): Observable<UserCalendar[]> {
    return this.http.get<ResponseItems<UserCalendar>>(`${this.url}-calendar`).pipe(
      map((response: ResponseItems<UserCalendar>) => response.data)
    );
  }

  /**
   * Get all users for event
   */
  getAllForEvent(): Observable<UserEvent[]> {
    return this.http.get<ResponseItems<UserEvent>>(`${this.url}-event`).pipe(
      map((response: ResponseItems<UserEvent>) => response.data)
    );
  }

  getOne(id: number): Observable<ResponseItem<User>> {
    return this.http.get<ResponseItem<User>>(`${this.url}/${id}`);
  }

  /**
   * Create user
   *
   * @param body
   */
  create(body): Observable<ResponseItem<User>> {
    return this.http.post<ResponseItem<User>>(this.url, body);
  }

  /**
   * Update user
   *
   * @param body
   */
  update(body: User): Observable<ResponseItem<User>> {
    return this.http.put<ResponseItem<User>>(`${this.url}/${body.id}`, body);
  }

  /**
   * Delete user
   *
   * @param id
   */
  delete(body: User): Observable<any> {
    return this.http.delete(`${this.url}/${body.id}`);
  }
}
