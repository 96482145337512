import { Component, EventEmitter,Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { UserService } from '@app/core/services/http/user.service';
import { UserEvent } from '@app/shared/interfaces/user';

@Component({
  selector: 'app-drawer-responsible-person',
  templateUrl: './drawer-responsible-person.component.html',
  styleUrls: ['./drawer-responsible-person.component.scss']
})
export class DrawerResponsiblePersonComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() users: UserEvent[] = [];
  @Output() userId = new EventEmitter<number>(null);
  color: '#3768B4';
  submitted = false;
  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private userService: UserService) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      phone: new FormControl(''),
      password: new FormControl(''),
      role: new FormControl(''),
      active: new FormControl('0'),
      color: new FormControl('')
    });
  }

  close(): void {
    this.isVisible = false;
  }

  public onSubmit(): void {
    this.submitted = this.form.invalid;

    if (this.submitted) {
      return;
    }
    const body = this.form.getRawValue();
    body.color = this.color;
    this.userService.create(body).subscribe({
      next: res => {
        this.users.unshift(res.data);
        this.userId.emit(res.data.id);
        this.close();
      }
    });
  }
}
