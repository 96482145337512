import 'dayjs/locale/ru';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ru from '@angular/common/locales/ru';
import { LOCALE_ID,NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import * as dayjs from 'dayjs';
import { NZ_DATE_CONFIG,NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';

import { CoreModule } from '@app/core/core.module';
import { jwtOptionsFactory } from '@app/core/factory/jwt-options-factory';
import { AuthGuard } from '@app/core/guards';
import { SharedModule } from '@app/shared/shared.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

registerLocaleData(ru);
dayjs.locale('ru');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [PLATFORM_ID]
      }
    }),
  ],
  providers: [
    AuthGuard,
    {provide: NZ_I18N, useValue: ru_RU},
    {provide: LOCALE_ID, useValue: 'ru'},
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
