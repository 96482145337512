import { NgModule } from '@angular/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTransferModule } from 'ng-zorro-antd/transfer';

@NgModule({
  imports: [
    NzDatePickerModule,
    NzSpinModule,
    NzSelectModule,
    NzCalendarModule,
    NzRadioModule,
    NzTabsModule,
    NzTransferModule,
    NzModalModule,
    NzCheckboxModule,
    NzDrawerModule,
    NzInputNumberModule,
    NzNotificationModule,
    NzAlertModule,
    NzEmptyModule
  ],
  exports: [
    NzDatePickerModule,
    NzSpinModule,
    NzSelectModule,
    NzCalendarModule,
    NzRadioModule,
    NzTabsModule,
    NzTransferModule,
    NzModalModule,
    NzCheckboxModule,
    NzDrawerModule,
    NzInputNumberModule,
    NzNotificationModule,
    NzAlertModule,
    NzEmptyModule
  ],
  providers: [NzNotificationService],
})
export class NgZorroModule {
}
