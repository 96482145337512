import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/guards';
import { Role } from '@app/shared/enum/role';
import {EquipmentsEventsModule} from "@app/modules/equipment/equipments-events.module";

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { canActivate: [AuthGuard], path: 'calendars', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule) },
  { canActivate: [AuthGuard], path: 'equipments', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/equipment/equipment.module').then(m => m.EquipmentModule) },
  { canActivate: [AuthGuard], path: 'equipments-events', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/equipment/equipments-events.module').then(m => m.EquipmentsEventsModule) },
  { canActivate: [AuthGuard], path: 'categories', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/category/category.module').then(m => m.CategoryModule) },
  { canActivate: [AuthGuard], path: 'reports', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule) },
  { canActivate: [AuthGuard], path: 'wall', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/wall/wall.module').then(m => m.WallModule) },
  { canActivate: [AuthGuard], path: 'events', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/event/event.module').then(m => m.EventModule) },
  { canActivate: [AuthGuard], path: 'users', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
  { canActivate: [AuthGuard], path: 'profile', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)},
  { canActivate: [AuthGuard], path: '', data: {roles: [Role.admin]}, loadChildren: () => import('./modules/event/event.module').then(m => m.EventModule) },
  { path: '**', redirectTo: '/'},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
