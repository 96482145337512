import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { catchError, finalize,map,  } from 'rxjs/operators';

import { Login, Token, User } from '@app/shared/interfaces/user';

@Injectable()
export class AuthService {
  private readonly accessToken = 'access_token';
  private readonly refreshToken = 'refresh_token';

  private readonly apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
  ) {
  }

  /**
   * User role
   */
  async getUserRole(): Promise<Array<string>> {
    const credentials = await this.decodeToken().catch(() => '');
    return credentials && credentials.user && credentials.user.role || '';
  }

  /**
   * Logout
   */
  logout(): void {
    localStorage.clear();
    this.router.navigate(['/auth']);
  }

  /**
   * Login - authorize
   *
   * @param loginData {Login}
   */
  login(loginData: Login): Observable<Token> {
    return this.http.post<any>(`${this.apiUrl}/login`, loginData)
      .pipe(
        map((res: Token) => {
          this.startToken(res);
          return res;
        })
      );
  }


  /**
   * Register new user
   *
   * @param body
   */
  public signUp(body): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/signup`, body);
  }

  /**
   * Password create - user
   *
   * @param body
   */
  public passwordCreate(body): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/password/create`, body);
  }

  /**
   * Password find - user
   *
   * @param token
   */
  public passwordFind(token: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/password/find/${token}`);
  }

  /**
   * Password reset - user
   *
   * @param body
   */
  public passwordReset(body): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/password/reset`, body);
  }

  /**
   * Activate user
   *
   * @param token
   */
  public signUpActivate(token: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/activate/token/${token}`);
  }

  /**
   * Token
   */
  tokenRefresh(): Observable<Token> {
    return this.http.post<Token>(`${this.apiUrl}/token/refresh`, {})
      .pipe(
        map((res: Token) => {

          this.startToken(res);
          return res;
        })
      );
  }

  private startToken(token: Token) {
    localStorage.clear();
    localStorage.setItem('time', String(token.expires_in));
    localStorage.setItem(this.accessToken, token.access_token);
    localStorage.setItem(this.refreshToken, token.refresh_token);
}

  /**
   * GET - user
   */
  public getUser(): User {
    const accessToken = this.getAccessToken();
    let decodeToken = null;
    try {
      decodeToken = this.jwtHelper.decodeToken(accessToken);
    } catch (e) {
      console.error(e);
    }
    return decodeToken && decodeToken.user ? decodeToken.user : null;
  }

  public getId(): number {
    const accessToken = this.getAccessToken();
    let decodeToken = null;
    try {
      decodeToken = this.jwtHelper.decodeToken(accessToken);
    } catch (e) {
      console.error(e);
    }
    return +decodeToken.sub;
  }

  /**
   * get access token
   */
  getAccessToken(): string {
    return localStorage.getItem(this.accessToken);
  }

  /**
   * get refresh token
   */
  getRefreshToken(): string {
    return localStorage.getItem(this.refreshToken);
  }

  /**
   * Decode token
   */
  private async decodeToken() {
    let token;

    try {
      let accessToken = this.getAccessToken();
      token = this.jwtHelper.decodeToken(accessToken);

      if (this.jwtHelper.isTokenExpired(accessToken)) {
        const data = await this.tokenRefresh();
        accessToken = this.getAccessToken();
        token = data ? this.jwtHelper.decodeToken(accessToken) : false;
      }

      return token || false;
    } catch (e) {
      return false;
    }
  }
}
