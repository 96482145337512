import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';

@Directive({
  selector: '[appDateDisplay]'
})
export class DateDisplayDirective implements OnInit {
  @Input() dateStart: number | null;
  @Input() dateFinish: number | null;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const dateStart = dayjs.unix(this.dateStart);
    const dateFinish = dayjs.unix(this.dateFinish);

    this.elementRef.nativeElement.textContent = `${dateStart.format('DD MMMM')} - ${dateFinish.format('DD MMMM')}`;
  }
}
