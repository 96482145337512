import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-notification',
  template: ''
})
export class NotificationComponent implements OnInit, OnDestroy {
  constructor(
    private notification: NzNotificationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.notificationService.getNotifications().subscribe({
      next: data => {
        if (data && data.type) {
          this.notification.create(data.type, data.title, data.body);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.notificationService.destroy();
  }
}
