import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Transport, TransportEvent } from '@app/shared/interfaces/transport';

@Injectable({
  providedIn: 'root'
})
export class TransportService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/transports`;
  }

  /**
   * Get all transports
   */
  getAll(): Observable<ResponseItems<Transport>> {
    return this.http.get<ResponseItems<Transport>>(this.url);
  }

  /**
   * Get all transports for event
   */
  getAllForEvent(): Observable<TransportEvent[]> {
    return this.http.get<ResponseItems<TransportEvent>>(`${this.url}-event`).pipe(
      map((response: ResponseItems<TransportEvent>) => response.data)
    );
  }

  /**
   * Create transport
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Transport>> {
    return this.http.post<ResponseItem<Transport>>(this.url, body);
  }
}
