import {Component, Input, OnInit, Output} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import {valiDationEvent} from "@app/shared/interfaces/event";

@Component({
  selector: 'app-modal-validation',
  templateUrl: './modal-validation-event.component.html',
  styleUrls: ['./modal-validation-event.component.scss']
})

export class ModalValidationEventComponent implements OnInit {

  @Input() data : valiDationEvent[];
  @Input() title;

  @Output() isComfirm: boolean;

  constructor(
    private modal: NzModalRef,
  ) {}

  ngOnInit(): void {

  }

  /**
   * Submit form
   */
  onSubmit(): void {

    }

  destroyModal(res = null): void {
    this.modal.destroy(res);
  }
}
