<div class="close" (click)="destroyModal()"></div>
<form [formGroup]="form" autocomplete="off" class="col-lg-12">

    <div class="modal-title-printed">Вывод на печать:</div>

    <div class="add-event-line" *ngFor="let order of reservationFormArray.controls; let i = index">
        <label formArrayName="reservations" class="label-printed">
            <input type="checkbox" [formControlName]="i" (change)="changeCheckbox()">
            <span class="text-printed">{{this.reservationsData[i]?.name || 'Бронирование № ' + (i + 1)}}</span>
        </label>
    </div>
    <div class="add-event-group text-center mt-3">
        <button class="add-new-user printed-btn ant-btn ant-btn-primary ml-0" (click)="onSubmit()" [disabled]="!isPrinted">Печатать</button>
    </div>
</form>