<div class="slide-right-user">
    <div class="add-event-user">
        <nz-drawer
            [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
            [nzClosable]="false"
            [nzVisible]="isVisible"
            nzPlacement="right"
            (nzOnClose)="close()"
            [nzWidth]="420"
        >
            <form class="add-user-drawer-form" [formGroup]="form">
                <div class="add-event-user-block">
                    <label>Почта</label>
                    <input class="ant-input" placeholder="Почта" formControlName="email"/>
                </div>
                <div class="add-event-user-block">
                    <label>ФИО</label>
                    <input class="ant-input" placeholder="Имя" formControlName="name"/>
                </div>
                <div class="add-event-user-block">
                    <label >Телефон</label>
                    <input type="tel" class="ant-input" placeholder="Номер телефона" formControlName="phone"/>
                </div>
                <div class="footer">
                    <button type="button" class="ant-btn ant-btn-primary add-btn-user" (click)="onSubmit()">
                        <span>Добавить</span>
                    </button>
                </div>
            </form>
        </nz-drawer>
    </div>
</div>
