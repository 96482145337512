<div class="close" (click)="destroyModal()"></div>
<form [formGroup]="form" autocomplete="off" class="col-lg-12">
    <div class="add-event-line" [ngClass]="{ 'has-error': submitted && form.get('name').invalid }">
        <label class="add-event-name" for="name">Имя<span>*</span>:</label>
        <div class="add-event-group">
            <input id="name" formControlName="name" class="two-line ant-input"/>
        </div>
    </div>
    <div class="add-event-line">
        <label class="add-event-name" for="phone">Телефон:</label>
        <div class="add-event-group">
            <input id="phone" formControlName="phone" class="two-line ant-input"/>
        </div>
    </div>
    <div class="add-event-line">
        <label class="add-event-name" for="transport">Транспорт:</label>
        <div class="add-event-group">
            <input id="transport" formControlName="transport" class="two-line ant-input"/>
        </div>
    </div>
    <div class="add-event-group text-center mt-3">
        <button class="add-new-user ant-btn ant-btn-primary ml-0" (click)="onSubmit()">Добавить</button>
    </div>
</form>
