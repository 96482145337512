<div class="close" (click)="destroyModal()"></div>
<form [formGroup]="form" autocomplete="off" class="col-lg-12">
    <input id="organisation" formControlName="organisation" class="two-line ant-input" type="hidden" value="Название организации"/>
    <div class="add-event-line">
        <label class="add-event-name" for="person">Контактное лицо : <span>*</span></label>
        <div class="add-event-group">
            <input id="person" formControlName="person" class="two-line ant-input"/>
        </div>
    </div>
    <div class="add-event-line">
        <label class="add-event-name" for="phone">Телефон:</label>
        <div class="add-event-group">
            <input id="phone" formControlName="phone" class="two-line ant-input"/>
        </div>
    </div>
    <div class="add-event-line">
        <label class="add-event-name" for="email">E-mail:</label>
        <div class="add-event-group">
            <input id="email" formControlName="email" class="two-line ant-input" type="email"/>
        </div>
    </div>
    <div class="add-event-group text-center mt-3">
        <button class="add-new-user ant-btn ant-btn-primary ml-0" (click)="onSubmit()" [ngClass]="{'no-active-bottom': form.invalid}" [disabled]="form.invalid">Добавить</button>
    </div>
</form>
<style>
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover {
        color:#8e8e8e !important;
    }
</style>