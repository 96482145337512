import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';
import { ErrorInterceptor } from '@app/core/interceptors/error.interceptor';
import { HttpConfigInterceptor } from '@app/core/interceptors/httpconfig.interceptor';
import { SuccessInterceptor } from '@app/core/interceptors/success.interceptor';

/**
 * Http interceptor providers in outside-in order
 */
export const InterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: SuccessInterceptor, multi: true},
];
