import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { ResponseItem } from '@app/shared/interfaces/base';
import { Media } from '@app/shared/interfaces/media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private readonly params: HttpParams;
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.params = new HttpParams();
    this.url = `${this.apiUrl}/media`;
  }

  /**
   * Create media
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Media>> {
    const headers = new HttpHeaders().set(environment.InterceptorSkipHeaderImage, '');
    return this.http.post<ResponseItem<Media>>(this.url, body, {
      headers,
      params: this.params,
      reportProgress: true
    });
  }
}
