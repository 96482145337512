import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationService } from '@app/core/services/notification.service';

@Injectable()
export class SuccessInterceptor implements HttpInterceptor {

  constructor(
    private notificationService: NotificationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: any) => {
          const message = event.body && event.body.meta && event.body.meta.message;
          if (event instanceof HttpResponseBase && message && (event.status === 200 || event.status === 201 || event.status === 202 || event.status === 204) ) {
            this.notificationService.create('success', message, '');
          }
        }
      ),
    );
  }
}
