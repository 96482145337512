import { Pipe, PipeTransform } from '@angular/core';

import { NO,YES } from '@app/app.constants';

@Pipe({name: 'status'})
export class StatusPipe implements PipeTransform {

  /**
   * Status
   *
   * @param status {boolean}
   * @return {string}
   */
  transform(status: boolean): string {
    return status ? YES : NO;
  }
}
