<div class="close" (click)="destroyModal()"></div>
<form [formGroup]="form" autocomplete="off" class="col-lg-12">
    <div class="add-event-line">
        <label class="add-event-name" for="email">E-mail:</label>
        <div class="add-event-group">
            <input id="email" formControlName="email" class="two-line ant-input" type="email"/>
        </div>
    </div>
    <div class="add-event-line">
        <label class="add-event-name" for="email">Телефон:</label>
        <div class="add-event-group">
            <input id="phone" formControlName="phone" class="two-line ant-input" type="text"/>
        </div>
    </div>
    <div class="add-event-line">
        <label class="add-event-name" for="first_name">Имя:</label>
        <div class="add-event-group">
            <input id="first_name" formControlName="first_name" class="two-line ant-input"/>
        </div>
    </div>
    <div class="add-event-line">
        <label class="add-event-name" for="last_name">Фамилия:</label>
        <div class="add-event-group">
            <input id="last_name" formControlName="last_name" class="two-line ant-input"/>
        </div>
    </div>
    <div class="add-event-group text-center mt-3">
        <button class="add-new-user ant-btn ant-btn-primary ml-0" (click)="onSubmit()">Добавить</button>
    </div>
</form>
