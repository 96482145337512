import {Component, Input, OnInit, Output} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-modify.component.html',
  styleUrls: ['./modal-modify.component.scss']
})

export class ModalModifyComponent implements OnInit {

  @Input() type: string;
  @Input() title: string;
  @Input() function: Function;

  @Output() isComfirm: boolean;

  constructor(
    private modal: NzModalRef,
  ) {}

  ngOnInit(): void {

  }

  /**
   * Submit form
   */
  onSubmit(): void {

    }

  destroyModal(res = null): void {
    this.modal.destroy(res);
  }
}
